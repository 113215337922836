@import "./ui-kit/_variables.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: url("../fonts/PlusJakartaSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-ExtraBold";
  src: url("../fonts/PlusJakartaSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/PlusJakartaSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url("../fonts/PlusJakartaSans-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "PlusJakartaSans-Regular", "Open Sans", sans-serif;
}

.text-light {
  font-weight: 300;
}

.text-semi-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-extra-bold {
  font-weight: 800;
}

.carousel-indicators{
    list-style-type: none;
}

// Scrollbar
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c4c4c4;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #c4c4c4;
}

// Empty State
.empty-state {
  text-align: center;
  padding-top: 25%;
}
.empty-state-caption {
  margin-top: 20px;
}

// Sidebar
.nav-container {
  position: fixed;
  width: 0px;
  top: 0;
  height: 100vh;
  z-index: 100;
 
  // background-color: $base-color-primary;
  background-color: #F6F6F6;
  transition: width 0.5s ease;
  cursor: pointer;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    width: 329px;
  }
  .active{
    color:#1C37BE;
  }
}
.nav-container.show{
  width: 329px !important;
}
.nav-ul,
.notification-ul {
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  padding-left: 1rem;
  &:first-child {
    padding-top: 1.5rem;
  }
}

.nav-items {
  padding: 15px 10px 10px 10px;
  a {
    // color: #ffffff80 !important;
    color: #6B6B6B !important;
    text-decoration: none;
    .sidebar-title {
      font-size: 16px;
      position: relative;
      top: -5px;
      padding-right: 15px;
      transition: all 0.3s ease;
      margin-left: 25px;
      margin-right: 10px;
      white-space: nowrap;
    }
    .sidebar-icon {
      padding: 10px;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      position: relative;
      cursor: pointer;
      display: inline;
    }
  }
  &:hover {
    background-color: #E5E5F0;
    .sidebar-icon {
      background: rgba(255, 255, 255, 0.2);
      color: #1C37BE;;
    }
    .sidebar-title{
      color: #1C37BE;
    }
    .iconify-inline{
      color: #1C37BE;
    }
  }
  a.active {
    .sidebar-icon {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
.nav-button {
  margin-right: 35px;
  margin-top: -47px;
  float: right;
  cursor: pointer;
}
.nav-items.active{
  background-color: #E5E5F0;
  .sidebar-icon {
    background: rgba(255, 255, 255, 0.2);
    color: #1C37BE;;
  }
  .sidebar-title{
    color: #1C37BE;
  }
  .iconify-inline{
    color: #1C37BE;
  }
}
.main-content {
  padding-left: 80px;
  min-height: 100vh;
}
#nav-ul-sidebar{
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
}
.nav-ul {
  height: calc(100vh - 180px);
}

.notification-ul {
  border-bottom: 1px solid #ffffff80;
  height: 70px;
}
.profile-menu {
  height: 110px;
}
.profile-menu {
  overflow-y: hidden;
  overflow-x: hidden;
  list-style-type: none;
  padding-left: 1rem;
  padding-top: 2rem;
}

.profile-items {
  padding: 10px 0px;
  a {
    display: flex;
    align-items: center;
    color: #ffffff !important;
    text-decoration: none;
    .profile-info {
      position: relative;
      top: -5px;
      transition: all 0.3s ease;
      margin-left: 25px;
      margin-right: 10px;
      white-space: nowrap;
      .profile-name {
        font-size: 20px;
      }
      .profile-position {
        font-size: 14px;
      }
    }
    .profile-img {
      padding: 10px;
      border-radius: 5px;
      width: 28px;
      height: 28px;
      position: relative;
      cursor: pointer;
      display: inline;
    }
  }
}
.main{
  display: flex;
  justify-content: center;
}
.mobile-container{
  width: 450px;
    height: 100vh;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.38); 
    box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.38);
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.mobile-container::-webkit-scrollbar {
  display: none;
}
.splash{
  height: 100%;
  background-color: white;
  transition: all 0.5s ease-out;
  overflow: hidden;
  width: 450px;
  position: fixed;
  z-index: 999;
}
.splash .carousel-indicators li{
  background-color: rgba(28, 55, 190, 0.5) !important;
}
.splash .carousel-indicators li.active{
  background-color: #1C37BE !important;
}
.splash.panjang{
  height: 0px;
}
.landing-logo{
  position: absolute;
  left: 15.5%;
  right: 15%;
  top: 30.31%;
  width: 70%;
}
.loading{
  top: 80%;
  position: absolute;
  left: 40.56%;
}
@media screen and (max-width:460px) {
  .mobile-container{
    width: 100%;
  }
  .splash{
    width: 100%;
  }
}
@media screen and (max-width:396px) {
  .landing-logo{
    width: 200px;
  } 
  .loading{
    top: 70%;
    position: absolute;
    left: 36.56%;
  }
}

.register{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.warn-text{
  color:red;
}
.header-register{
  background-image: url('../img/register/header.png');
  height: 120px;
  background-size: cover;
  padding: 25px;
  .logo{
    height: 26px;
    // width: 140px;
    width: auto;
    left: 30px;
    top: 28px;
    border-radius: 0px;
  }
  p{
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 23px;
  }
}
.header-logo{
  height: 120px;
  background-size: cover;
  padding: 25px;
  .logo{
    height: 26px;
    width: 140px;
    left: 30px;
    top: 28px;
    border-radius: 0px;
  }
}
.inherited-parent{
  height: inherit;
  width: inherit;
}
.form-register{
    background-color: white;
    overflow: initial;
    height: auto;
    margin-top: -20px;
    border-radius: 22px;
    max-height: inherit;
    padding: 40px;
}
.box-upload{
  height: auto;
  background-color: #F6F6F6;
  padding: 20px;
  label{
    display: flex;
  }
  .img-preview{
    min-width: 60px !important;
    width: 60px;
    height: 60px;
  }
  .filename{
    margin-left: 20px;
    p{
      margin-bottom: 0.5rem;
    }
  }
}
.button-kirim{
 
width: 100%;
margin-top: 10px;
/* Main */
background: #1C37BE;
border-radius: 24px;
color: #ffffff;
padding: 5px;
}

.button-kembali{
width: 100%;
margin-top: 10px;
background: #ffffff;
border-radius: 24px;
border: 2px solid #1C37BE;
color:  #1C37BE;
padding: 5px;
}

.content{
  padding: 30px;
  height: 100%;
}
.logo-response{
  // margin-top: 25%;
  display: flex;
  justify-content: center;
}
.gambar-failed{
  height: auto;
  width: 100%;
  left: 30px;
  top: 28px;
  border-radius: 0px;
}
.gambar-success{
  height: 200px;
  width: auto;
  left: 30px;
  top: 28px;
  border-radius: 0px;
}
.caption-response{
  text-align: center;
  padding: 30px;
}
.caption-response-splash{
  text-align: center;
  padding: 30px;

  h5{
    color: #1C37BE;
    font-size: 18px;
  }
  p{
    color: #3F3F3F;
    font-size: 14px;
  }
}
.twibbon-border {
  padding: 50px;
  background: linear-gradient(0deg, #F6F6F6 70%, #1C37BE 30%);
  border-radius: 30px;
  .twibbon-image{
    display: flex;
    justify-content: center;
    .img-preview{
      width: 200px;
      height: 200px;
    }
  }
  p {
    text-align: center;
  }
}

.scale-twibbon {
  position: relative;
  top: 0;
  left: 0;

  p {
    text-align: center;
    margin-bottom: 0px;
  }

  .bg-twibbon {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: 1;
  }
  .image-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }
  .foto-twibbon{
    width: 100%;
    height: 100%;
    padding: 0px 0px 1px 1px;
  }
}

.image-crop {
  position: relative;
  height: 300px;
  max-height: 300px;
  .twibbon-image{
    display: flex;
    justify-content: center;
    .img-preview{
      width: 100%;
      height: 100%;
    }
  }
}

.slider-crop-image {
  border: 1px solid #1C37BE;
  border-radius: 30px;
  padding: 5px 30px 3px 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

//galery
.section-detail-gallery{
  height: 100%;
  position: relative;
}
.form-gallery{
  background-color: white;
  overflow: scroll;
  height: inherit;
  margin-top: -20px;
  border-radius: 22px;
  max-height: inherit;
  padding: 40px;
}
.card-gallery {
  padding: 25px 25px 15px 25px;
  margin-bottom: 20px;
  border-radius: 30px;
  background: #F6F6F6;
  border: 1px solid #e6e4e4;
  .gallery-image{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    .img-preview{
      width: 100%;
      height: 150px;
      border-radius: 30px;
    }
  }
  .col-gallery {
    align-self: center;
  }
  .title-gallery {
    color: #000000;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .desc-publish {
    color: #6B6B6B;
    font-size: 10px;
    display: flex;
    margin: auto;
  }
  .btn-click-gallery {
    border: none;
    background-color: #1C37BE;
    border-radius: 50%;
    color: white;
    padding-bottom: 4px;
    padding-left: 7px;
    padding-right: 7px;
  }
}
.form-detail-gallery{
  background-color: white;
  overflow: scroll;
  height: inherit;
  margin-top: -20px;
  border-radius: 22px;
  max-height: inherit;
  padding: 40px;
  width: 100%;
}
.card-detail-gallery {
  padding: 10px;
  margin-bottom: 20px;
  background: white;
  border-radius: 30px;
  .gallery-image{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    .img-preview{
      width: 100%;
      height: 150px;
      border-radius: 30px;
    }
    .carousel-indicators{
      top: 157px;
      cursor: pointer;
      li{
        background-color: rgba(28, 55, 190, 0.5);
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 10px;
      }
      li.active{
        background-color: #1C37BE;
        width: 10px;
        height: 10px;
      }
    }
  }
  .title-gallery {
    color: #000000;
    font-size: 14px;
  }
  .desc-publish {
    color: #6B6B6B;
    font-size: 10px;
  }
  .detail-gallery {
    font-size: 12px;
    color: #000000;
    margin-top: 30px;
  }
}
.btn-detail-gallery {
  border: none;
  color: #1C37BE;
  background-color: white;
  font-size: 20px;
  margin-left: -5px;
  margin-bottom: 10px;
}
.fixed-button-next {
  // border: none;
  // background-color: #1C37BE;
  // color: white;
  // padding-left: 25px;
  // padding-right: 25px;
  // padding-bottom: 8px;
  // padding-top: 5px;
  // border-radius: 20px;
  // position: sticky;
  // bottom: 50px;
  // float: right;

  text-decoration: none;
  position: fixed;
  padding: 5px 15px;
  bottom: 40px;
  right: 40px;
  background-color: #1C37BE;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;
}
.fixed-button-next:hover{
  color: white;
}
.redeem{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  // height: 100%;
}
.caption-redeem{
  text-align: center;
}
.primary{
    width: 100px;
    border: solid;
    border-radius: 25px;
    border-color: #1C37BE;
    color: #ffffff;
    background-color: #1C37BE;
}
.secondary{
    width: 100px;
    border: solid;
    border-radius: 25px;
    border-color: #1C37BE;
    color: #1C37BE;
    background-color: #ffffff
}
.danger{
  height: 40px;
  border: none;
  border-radius: 25px;
  background-color: #F02D2D;
  color: #ffffff;
}
#modal-confirmation-redeem{
  top: 25%;
  text-align: center;
  width: 93%;
}
.voucher-id{
  background-color:#F5F5F5;
  padding: 10px;
  text-align: center;
  border-radius: 6px;
  margin-bottom: 10px;
  p{
    margin-bottom: 0px;
  }
}
/* CONTACT-US SECTION  */
.floating-whatsapp{
  position: fixed;
  padding: 0px 20px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #4CC737;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
a.floating-whatsapp:hover {
  color: #FFF !important;
}
.btn-whatsapp{
  margin-top:14px;
}
.floating-faq{
  position: fixed;
  padding: 0px 15px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: #FFA115;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;
  .icon{
    width: 30px;
    margin-top: 10px;
  }
}
.card-faq{
  cursor: pointer;
}
.title-question{
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #EAEAEA;
  border-radius: 6px;
  margin-top: 10px;
  p{
    margin-bottom: 0px;
  }
}
.answer{
  background-color: #F6F6F6;
  padding: 15px;
  margin-top: 5px;
  p{
    margin-bottom: 0px;
  }
}
.hide{
  display: none;
}
.splash{
  .carousel{
    height: 100%;
  }
  .carousel-inner{
    height: 100%;
  }
  .carousel-item{
    height: 100%;
    // img{
    //   height: 100%;
    // }
  }
  .carousel-indicators{
    bottom: 30px !important;
    right: 50% !important;
    cursor: pointer;
    li{   
      background-color: rgba(28, 55, 190, 0.5);
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 10px;
    }
    li.active{  
      background-color: #1C37BE;
      width: 10px;
      height: 10px;
    }
  }
}
.daftar-sekarang{
  position: absolute;
  z-index: 1000;
  bottom: 30px;
  right: 22px;
  button{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    padding: 15px;
    background-color: #1C37BE;
    color: white;
    border: unset;
    // font-weight: bold;
  }
}
@media screen and (max-width:768px) {
  .daftar-sekarang{
    margin-right: unset;
    right:150px
  }
}
@media screen and (max-width:465px){
  .daftar-sekarang{
    margin-right: unset;
    right: 15px;
  }
}
@media screen and (max-width:400px){
  .landing-logo{
    left: 25.5%;
  }
}

.form-select-style {
  border: 1px solid;
  border-color: #d6d6d6;
  opacity: 10px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.form-label-select {
  font-size: 14px;
  font-family: "PlusJakartaSans-Bold";
  margin-bottom: 12px;
  font-weight: bold;
}

.MuiInput-input {
  background-color: white !important;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.form-check-label {
  width: 100%;
}

.form-privacy {
  cursor: pointer;
}

.title-privacy {
  font-size: 23px;
  color: #1C37BE;
  font-weight: bolder;
  text-align: center;
  padding-right: 20%;
}

@media screen and (max-width:350px){
  .title-privacy{
    padding-right: 0px;
  }
}

.box-rounded{
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 30px;
  }
  #table-privacy{
  width: 100%;
  tr{
    height: 100px;
    // word-break: break-word;
    th{
      padding: 20px;
    }
  }
}

.gallery-image-home-slider {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  height: 330px;
  .img-preview{
    width: 100%;
    height: 150px;
    border-radius: 30px;
  }
  .carousel {
    width: 100%;
  }
  .carousel-indicators{
    bottom: -10px;
    cursor: pointer;
    li{
      background-color: rgba(28, 55, 190, 0.5);
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 10px;
    }
    li.active{
      background-color: #1C37BE;
      width: 10px;
      height: 10px;
    }
  }
  .title-gallery {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
}

.icon-eye {
  width: 15px;
  height: 15px;
  margin-top: 1px;
  margin-right: 3px;
}

.count-eye {
  font-size: 10px;
  color: #6B6B6B;
}

.title-label-gallery {
  font-weight: bold;
  font-size: 18px;
}

.section-eye {
  margin-top: -10px;
}

.tb-peserta-bulan {
  color: #6B6B6B;
  font-size: 12px;
}

.dropdown-bulan {
  background-color: #DEEBFF;
  color: #1C37BE;
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
  border-color: white;
  border-radius: 5px;
}

.dropdown-bulan:hover{
  background-color: #DEEBFF;
}

.dropdown-menu-bulan {
  font-size: 12px;
}

.gallery-twibbon {
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 24px;
  background-color: #DEEBFF !important;
  padding: 10px;
}


.image-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(50px, auto);
  
  .image-item:nth-child(5n){
    grid-column-end: span 2;
  }
  
  img{
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.twibbon-peserta {
  margin: 20px 0px;
}

.empty-twibbon {
  display: inline-grid;
  justify-content: center;
  margin: 20px 0px;

  .img-preview-empty {
    width: 150px;
    height: 150px;
    margin: auto;
    margin-bottom: 20px;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  p {
    color: #6B6B6B;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}

.style-frame-twibbon {
  position: relative;

  .frame-twibbon {
    margin: auto;
    margin-top: 19px;

    .img-preview{
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 46%;
      transform: translate(-50%, -50%);
      // width: 40%;
    }
  }
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #1C37BE !important;
}

.icon-sticker {
  height: 20px;
  width: 20px;
  margin: 0px 10px 3px;
}

.button-stiker {
  width: 100%;
  background: #1C37BE;
  color: #ffffff;
  padding: 5px;
  border-color: #1C37BE;
  margin-bottom: 10px;
}

.img-preview-crop{
  height: 100%;
  width: 100%;
}

.hasil-image-crop {
  position: relative;
  height: 300px;
  max-height: 300px;
  .twibbon-image{
    // display: flex;
    // justify-content: center;
    .img-preview{
      width: 100%;
      height: 100%;
    }
  }
}

.stage-canvas-sticker {
  .konvajs-content {
    width: 100% !important;
  }
  canvas {
    width: 100% !important;
  }
}

.modal-content {
  background: rgba(0,0,0,0.7);
  color: white;

  button {
    background: none;
    color: white;
    border: none;
    font-size: 25px;
  }
}

.press {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: auto;
  max-height: 500px;
  
  li {
    display: inline-block;
    width: 33.3%;
    text-align: center;
    margin-bottom: 30px;
  }
}

.bg-tb-form {
  position: relative;
  min-width: 60px !important;
  width: 60px;
  height: 60px;
  max-width: 60px !important;
  max-height: 60px !important;

  .img-preview{
    min-width: 60px !important;
    width: 60px;
    height: 60px;
  }
}

.bg-tb-form-hidden {
  position: relative;
  min-width: 60px !important;
  width: 300px;
  height: 300px;
  max-width: 300px !important;
  max-height: 300px !important;

  .img-preview{
    min-width: 300px !important;
    width: 300px;
    height: 300px;
  }
}

.search-container {
  position: relative;
  .input-search {
    height: 53px;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    width: 0px;
    &.show-input {
      visibility: visible;
      width: 200px;
      padding-right: 40px;
    }
  }
  .search-toggle {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
  }
}

.search-region{
  background: #FFFFFF;
  border: 1px solid #E5E5F0;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  padding: 10px;
}

.col-search{
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn-icon-search {
  i {
    padding: 7px;
    border-radius: 8px;
    color: #B8B8D6;
    cursor: pointer;
  }
  input {
    border: 0px;
    width: 100%;
  }
  input:focus {
    outline: none !important;
  }
}

.box-search-filter {
  display: flex;
  width: 100%;
}

.filter-region {
  width: 18%;
  margin-left: 10px;
  color: #1C37BE;
  cursor: pointer;
  padding: 10px;
  justify-content: center;

  span {
    width: 100px;
  }
}




.box-list-store {
  display: flex;
  width: 100%;

  .store-list-logo {
    color: #1C37BE;
    img {
      height: 50px;
      width: 70px;
      float: right;
    }
    span {
      float: right;
    }
  }

  .title-list-logo {
    width: 100%;
    margin: auto;
    padding: 0 20px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
    }
  }

  .location-list-store {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    padding: 0 20px;
    color: #919191;
  }

}

.border-hr-list-store {
  border-bottom: 1px solid rgba(128, 128, 128, .2);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.area-list-store{
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  margin: 10px 50px;
  color: #919191;
}

.list-store {
  padding: 10px 20px;
  margin: 20px 0px;
  -webkit-box-shadow: 0px 10px 17px -9px rgba(0,0,0,0.2); 
  box-shadow: 0px 10px 17px -9px rgba(0,0,0,0.2);
}

.modal-filter {
  .modal-content {
    background: rgba(0, 0, 0, 0);
    color: white;
}
}

.list-popup-filter {
  margin: 20px 0px;
  .list-item-store {
    background-color: #F6F6F6;
    border-radius: 8px;
    padding: 10px 5px;
    margin: 10px;
    cursor: pointer;

    img {
      width: 50px;
      height: 30px;
    }
    span {
      font-size: 12px;
      color: black;
      margin: 5px 10px;
    }
  }

  .list-item-store-pick {
    background-color: #F6F6F6;
    border-radius: 8px;
    padding: 10px 5px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid #1C37BE;
    
    img {
      width: 50px;
      height: 30px;
    }
    span {
      font-size: 12px;
      color: black;
      margin: 5px 10px;
    }
  }
}

.modal-filter-footer {
  .btn-batal {
    background-color: white;
    border: 2px solid #1C37BE;
    border-radius: 10px;
    color: #1C37BE;
    font-size: 15px;
    font-weight: bold;
  }

  .btn-terapkan {
    background-color: #1C37BE;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    font-weight: bold;
    margin-left: 10px;
  }
}

.press-store {
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    display: inline-block;
    width: 33.3%;
    text-align: center;
    margin-bottom: 30px;
  }
}